import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 5,
  list: []
};

const adminReducer = ( state, action: DispatchAction ) => {
  switch (action.type) {
    // Register reducer
    case Actions.GET_ADMINISTRATOR_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_ADMINISTRATOR_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      };
    }
    case Actions.GET_ADMINISTRATOR_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.ADMINISTRATOR_REGISTER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.ADMINISTRATORS_REGISTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: [...state.list, action.payload.data]
      }
    }
    case Actions.ADMINISTRATORS_REGISTER_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.ADMINISTRATOR_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.ADMINISTRATORS_UPDATE_SUCCESS: {
      return {
        ...state,
        list: state.list.map((row) => row.ID === action.payload.data['ID'] ? {...row, ...action.payload.data } : row),
        isLoading: false
      }
    }
    case Actions.ADMINISTRATORS_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.ADMINISTRATOR_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.ADMINISTRATORS_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.ADMINISTRATORS_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.filter((item) => item.ID !== action.payload.data.ID)
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }
};

export default adminReducer;