import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
    isLoading: false,
    isSignedIn: false,
    error: null,
    currentAdmin: null,
};

const loginReducer = ( state, action: DispatchAction ) => {
    switch (action.type) {
      // Register reducer
      case Actions.ADMINISTRATOR_LOGIN_REQUEST: {
        return {
          ...state,
          isLoading: true
        };
      }
      case Actions.ADMINISTRATOR_LOGIN_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          isSignedIn: true
        };
      }
      case Actions.ADMINISTRATOR_LOGIN_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isSignedIn: false,
        }
      }
      // Login with token reducer
      case Actions.LOGIN_WITH_TOKEN_REQUEST: {
        return {
          ...state,
          isLoading: true
        };
      }
      case Actions.LOGIN_WITH_TOKEN_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          currentAdmin: action.payload
        };
      }
      case Actions.LOGIN_WITH_TOKEN_FAILURE: {
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
      }
      case Actions.ADMINISTRATOR_LOGOUT: {
        return initialState;
      }
      default: {
        if(!state)
          return initialState;
        return state;
      }
    }
  };
  
  export default loginReducer;