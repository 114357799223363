import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const cognitouserReducer = ( state, action: DispatchAction ) => {
  switch (action.type) {
    // Register reducer
    case Actions.GET_COGNITOUSER_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_COGNITOUSER_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: [...action.payload.data],
        isLoading: false
      };
    }
    case Actions.GET_COGNITOUSER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.DELETE_COGNITOUSER_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.DELETE_COGNITOUSER_SUCCESS: {
      return {
        ...state,
        list: state.list.map((row) => row.Username === action.payload.Username ? action.payload : row),
        isLoading: false
      }
    }
    case Actions.DELETE_COGNITOUSER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.UPDATE_COGNITOUSER_EMAILVERIFIED_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.UPDATE_COGNITOUSER_EMAILVERIFIED_SUCCESS: {
      return {
        ...state,
        list: state.list.map((row) => row.Username === action.payload.Username ? action.payload : row),
        isLoading: false
      }
    }
    case Actions.UPDATE_COGNITOUSER_EMAILVERIFIED_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.COGNITOUSER_UPDATEINFO_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.COGNITOUSER_UPDATEINFO_SUCCESS: {
      let newList = state.list.map((row) => {
        if(row.Username === action.payload.Username) {
          let newAttributes = [...action.payload.Attributes];
          
          row.Attributes.map(preAttributeItem => {
            const payloadAttributeItem = newAttributes.find(attributeItem => attributeItem.Name === preAttributeItem.Name);
            
            if(payloadAttributeItem === undefined)
              newAttributes = [...newAttributes, preAttributeItem];

          });

          return {
            ...row,
            Attributes: newAttributes
          };
        } else {
          return row;
        }
      });

      return {
        ...state,
        list: newList,
        isLoading: false
      }
    }
    case Actions.COGNITOUSER_UPDATEINFO_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    default: {
      if(state)
        return state;
      return initialState;
    }
  }
};

export default cognitouserReducer;
