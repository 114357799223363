import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";

import * as Actions from "../constants";
import { LoginUserInput, RegisterUserInput } from "../../lib/validations/user.schema";
import { ResetPasswordActionInput, VerifyEmailInput } from "../../types/user";
import { handleAxiosError } from "../../utils/functions";

// Login action
export const loginAdmin: any = (data: LoginUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.ADMINISTRATOR_LOGIN_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/admin/login`, data)
    .then(response => {
      dispatch({ type: Actions.ADMINISTRATOR_LOGIN_SUCCESS });
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        dispatch(loginWithToken(true));
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.ADMINISTRATOR_LOGIN_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Login with JWT token action
export const loginWithToken: any = (refresh: boolean = true) => async (dispatch: Dispatch) => {
  if (refresh) {
    dispatch({ type: Actions.LOGIN_WITH_TOKEN_REQUEST });
  }

  const accessToken: string | null = localStorage.getItem("access-token");
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  return axios.get(`${process.env.REACT_APP_SERVER_API}/admin/access-token`)
    .then(response => {
      dispatch({
        type: Actions.LOGIN_WITH_TOKEN_SUCCESS,
        payload: response.data
      });
      localStorage.setItem("currentadministrator", response.data);
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: Actions.LOGIN_WITH_TOKEN_FAILURE,
        error
      });
      localStorage.removeItem("access-token");
      localStorage.removeItem("currentadministrator");
    });
};

// Logout user action
export const logoutAdmin: any = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("currentadministrator");
  axios.defaults.headers.common.Authorization = "Bearer ";
  return { type: Actions.ADMINISTRATOR_LOGOUT };
};