import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const userReducer = ( state, action: DispatchAction ) => {
  switch (action.type) {
    // Register reducer
    case Actions.GET_USER_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: [...action.payload.data],
        isLoading: false
      };
    }
    case Actions.GET_USER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.UPDATE_USER_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        list: state.list.map((row) => row.ID === action.payload.data['ID'] ? action.payload.data : row),
        isLoading: false
      }
    }
    case Actions.UPDATE_USER_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.USER_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.USER_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.USER_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.filter((row) => row.ID !== action.payload.data.ID)
      }
    }
    default: {
      if(state)
        return state;
      return initialState;
    }
  }
};

export default userReducer;
